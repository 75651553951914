<script>
	import logins from '$svr/providers/logins.json';
	import { slide } from 'svelte/transition';
	import { _, locale } from 'svelte-i18n';
	import { createEventDispatcher } from 'svelte';
	import Dropdown from './Dropdown.svelte';
	import { DEFAULT_MANAGED_LOGO } from '$src/constants.js';

	/*
	List of known mastodon servers and servers that use Hellō for login.
	This list is checked before calling nodeinfo endpoint.
	Servers that use Hellō for login are not allowed for login.
	*/
	import { mastodonSeed } from '$utils/mastodon.js';
	import { getDisplay, isValidDomain } from '$utils/common.js';
	import { getMastodonDiscovery } from '$utils/api.js';
	import SpinnerIcon from './icon/SpinnerIcon.svelte';
	import PasskeyIcon from './icon/PasskeyIcon.svelte';

	const dispatch = createEventDispatcher();

	export let login = false;
	export let mastodonExpanded = false;
	export let hideMastodonDropdown = false;
	export let autofocus = false;
	export let provider = {};
	export let prefix = '';
	export let hideusername = false;
	export let label = '';
	export let loading = false;
	export let disabled = false;
	export let strikethrough = false;
	export let logo = '';
	export let managed = false;

	let value = '';
	let showInvalidDomainErrorText = false;
	let showMastodonServerNotFoundErrorText = false;
	let knownLoginErrorText = false;
	let mastodonServerButtonDisabled = true;
	let checkMastodonServerAjax = false;

	$: {
		if (mastodonExpanded) {
			//reset all states when expanded mastodon dropdown
			value = '';
			checkMastodonServerAjax =
				showInvalidDomainErrorText =
				showMastodonServerNotFoundErrorText =
				knownLoginErrorText =
					false;
		}
	}

	let timer;
	async function handleMastodonServerInput() {
		checkMastodonServerAjax =
			showInvalidDomainErrorText =
			showMastodonServerNotFoundErrorText =
			knownLoginErrorText =
				false;
		mastodonServerButtonDisabled = true;
		const server = value;

		if (timer) clearTimeout(timer);
		if (Object.keys(mastodonSeed).includes(value)) {
			if (login && mastodonSeed[value].hello_login && !window.isWalletMastodonApp) {
				knownLoginErrorText = true;
			} else {
				mastodonServerButtonDisabled = false;
			}
		} else {
			timer = setTimeout(async () => {
				if (!server.length) return;
				const invalidDomain = !isValidDomain(server);
				if (invalidDomain) {
					showInvalidDomainErrorText = true;
					return;
				}
				checkMastodonServerAjax = true;
				let discoRes;
				try {
					discoRes = await getMastodonDiscovery(server);
					if (server !== value) return;
					if (discoRes?.software?.name === 'mastodon') {
						if (discoRes?.metadata?.hello) {
							knownLoginErrorText = true;
						} else {
							mastodonServerButtonDisabled = false;
						}
					} else {
						showMastodonServerNotFoundErrorText = true;
						mastodonServerButtonDisabled = true;
					}
				} catch (err) {
					mastodonServerButtonDisabled = true;
					if (err.status === 400) showInvalidDomainErrorText = true;
					if (err.status === 500 || err.status === 502) showMastodonServerNotFoundErrorText = true;
				}
				checkMastodonServerAjax = false;
			}, 650);
		}
	}
	$: display = provider.display || logins.find((i) => i.slug === provider.slug)?.display;
</script>

{#if provider.slug === 'mastodon' && !provider.user_name}
	{#if hideMastodonDropdown}
		<form on:submit|preventDefault={() => dispatch('click', value)} transition:slide|local>
			<!-- svelte-ignore a11y-autofocus -->
			<input
				autofocus
				type="text"
				name="mastodon-server"
				id="mastodon-server"
				bind:value
				on:input={handleMastodonServerInput}
				autocapitalize="off"
				placeholder="enter your Mastodon server (mastodon.example)"
				class="px-[16px] sm:px-[18px] w-full h-12 bg-transparent"
			/>
			{#if showInvalidDomainErrorText}
				<span
					class="text-red-500 text-left mt-2 block"
					data-test="mastodon-input-error"
					transition:slide|local>{$_('Invalid domain')}</span
				>
			{:else if showMastodonServerNotFoundErrorText}
				<span
					class="text-red-500 text-left mt-2 block"
					data-test="mastodon-input-error"
					transition:slide|local>{$_('Mastodon server not found')}</span
				>
			{:else if knownLoginErrorText}
				<span
					class="text-red-500 text-left block mt-2"
					data-test="mastodon-input-error"
					transition:slide|local>{$_('This server uses Hellō for login')}</span
				>
			{:else if checkMastodonServerAjax}
				<div class="flex justify-start items-center mt-2" transition:slide|local>
					<SpinnerIcon css="h-5 w-5 text-charcoal dark:text-white" />
					<span class="text-left block opacity-80 ml-2">{$_('Checking')}</span>
				</div>
			{/if}
			<button
				data-test="mastodon-continue-btn"
				class="btn-background w-full mt-4 flex items-center justify-center"
				disabled={mastodonServerButtonDisabled}
			>
				Continue
			</button>
		</form>
	{:else}
		<Dropdown
			dataTest="mastodon-btn"
			ariaLabel={$_(`${prefix} {provider}`, { values: { provider: display } })}
			expanded={mastodonExpanded}
			on:click={() => {
				mastodonExpanded = !mastodonExpanded;
			}}
		>
			<div class="h-12 w-full flex items-center justify-start px-4 gap-x-4">
				<img
					src="https://cdn.hello.coop/images/mastodon.svg"
					alt="Mastodon"
					class="w-4.5 max-h-[18px]"
				/>
				<span class="block text-left" aria-hidden="true">
					{$_(`${prefix} {provider}`, { values: { provider: display } })}
				</span>
			</div>
			{#if mastodonExpanded}
				<form
					class="pb-3 pt-1 px-4"
					on:submit|preventDefault={() => dispatch('click', value)}
					transition:slide|local
				>
					<!-- svelte-ignore a11y-autofocus -->
					<input
						autofocus
						type="text"
						name="mastodon-server"
						id="mastodon-server"
						bind:value
						on:input={handleMastodonServerInput}
						autocapitalize="off"
						placeholder="enter your Mastodon server (mastodon.example)"
						class="px-[16px] sm:px-[18px] w-full h-12 bg-transparent"
					/>
					{#if showInvalidDomainErrorText}
						<span
							class="text-red-500 text-left mt-2 block"
							data-test="mastodon-input-error"
							transition:slide>{$_('Invalid domain')}</span
						>
					{:else if showMastodonServerNotFoundErrorText}
						<span
							class="text-red-500 text-left mt-2 block"
							data-test="mastodon-input-error"
							transition:slide>{$_('Mastodon server not found')}</span
						>
					{:else if knownLoginErrorText}
						<span
							class="text-red-500 text-left block mt-2"
							data-test="mastodon-input-error"
							transition:slide>{$_('This server uses Hellō for login')}</span
						>
					{:else if checkMastodonServerAjax}
						<div class="flex justify-start items-center mt-2" transition:slide>
							<SpinnerIcon css="h-5 w-5 text-charcoal dark:text-white" />
							<span class="text-left block opacity-80 ml-2">{$_('Checking')}</span>
						</div>
					{/if}
					<button
						data-test="mastodon-continue-btn"
						class="btn-background w-full mt-4 flex items-center justify-center"
						disabled={mastodonServerButtonDisabled}
					>
						{$_('Continue')}
					</button>
				</form>
			{/if}
		</Dropdown>
	{/if}
{:else}
	{@const dataTest = [
		//not the provider.managed from logins.json
		(managed || (provider.managed && !provider.claims)) && 'managed',
		provider.slug,
		'btn'
	]
		.filter(Boolean)
		.join('-')}
	<!-- eg: managed-google-btn -->
	<!-- svelte-ignore a11y-autofocus -->
	<button
		{autofocus}
		{disabled}
		data-test={dataTest}
		class="group disabled:opacity-50 btn-background disabled:cursor-not-allowed w-full relative overflow-hidden flex items-center justify-start px-4"
		class:cursor-not-allowed={disabled}
		class:h-18={provider.user_name && !hideusername}
		on:click={() => {
			if (provider.slug === 'ethereum') dispatch('ethereum');
			else dispatch('click');
		}}
	>
		{#if loading}
			<SpinnerIcon css="h-5 w-5 block mx-auto" />
		{:else}
			{#if provider.slug === 'ethereum'}
				<img
					src={provider.wallet?.icon || 'https://cdn.hello.coop/images/ethereum.svg'}
					alt={provider.wallet?.name || 'Ethereum'}
					class={provider.user_name && !hideusername
						? 'mt-0 w-9 max-h-[36px]'
						: 'w-4.5 max-h-[18px]'}
				/>
			{:else if provider.slug === 'passkey'}
				<PasskeyIcon css="mt-0.5 h-4.5 w-4.5" />
				<!-- not the provider.managed from logins.json -->
			{:else if managed || (provider.managed && !provider.claims)}
				{#if provider.user_name && !hideusername}
					<span class="managed-rounded-square-lg">
						<img
							src={logo || provider.logo || DEFAULT_MANAGED_LOGO}
							alt="logo"
							class="mt-0 w-9 max-h-[36px] object-contain"
						/>
					</span>
				{:else}
					<span class="managed-rounded-square-sm">
						<img
							src={logo || provider.logo || DEFAULT_MANAGED_LOGO}
							alt="logo"
							class="w-4.5 max-h-[18px]"
						/>
					</span>
				{/if}
			{:else}
				<img
					src={logo || `https://cdn.hello.coop/images/${provider.slug}.svg`}
					alt={display}
					class="{provider.user_name && !hideusername
						? 'mt-0 w-9 max-h-[36px]'
						: 'w-4.5 max-h-[18px]'} object-contain"
				/>
			{/if}

			{#if provider.user_name}
				<div
					class="{$locale && $locale.startsWith('ar')
						? 'mr-4 text-right'
						: 'ml-4 text-left'} truncate"
				>
					<span class="block">
						{#if provider.slug === 'ethereum'}
							{$_(`${prefix} {provider}`, { values: { provider: $_('extension') } })}
						{:else if managed || provider.managed}
							{$_('Continue with')}
						{:else}
							{$_(`${prefix} {provider}`, { values: { provider: getDisplay(provider.slug) } })}
						{/if}
					</span>

					{#if !hideusername}
						<span class="block truncate">
							{#if provider.slug === 'ethereum'}
								{provider.wallet?.name || 'Ethereum'}
							{/if}

							{#if provider.slug === 'mastodon'}
								@{provider.user_name}@{provider.mastodonServer}
							{:else}
								{provider.user_name}
							{/if}
						</span>
					{/if}
				</div>
			{:else}
				<span
					class:line-through={strikethrough}
					class="block truncate {$locale && $locale.startsWith('ar')
						? 'mr-4 text-right'
						: 'ml-4 text-left'}"
				>
					{#if label}
						{label}
					{:else}
						{$_(`${prefix} {provider}`, {
							values: {
								provider: display
							}
						})}
					{/if}
				</span>
			{/if}

			<!-- Right arrow icon -->
			<svg
				xmlns="http://www.w3.org/2000/svg"
				class="flex-shrink-0 {$locale && $locale.startsWith('ar')
					? 'mr-auto rotate-90'
					: 'ml-auto -rotate-90'} h-4.5 opacity-80 transform text-white dark:text-[#d4d4d4] group-focus:stroke-3 group-hover:stroke-3 {provider.user_name &&
				!hideusername
					? 'mb-5.5'
					: ''}"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
			</svg>
		{/if}
	</button>
{/if}
